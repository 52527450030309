import React from "react"
import { StaticQuery, graphql } from "gatsby"
import TransitionLink from "gatsby-plugin-transition-link/AniLink"
import { pageTransitionEnter, pageTransitionExit } from "../animations"
import { isMobile } from "react-device-detect"
import { Link } from "gatsby"

export class NavFooter extends React.Component {
  render() {
    const menus = this.props.data.allWordpressWpApiMenusMenusItems.edges

    // Expected name is for example main-menu-en, footer-menu-de, etc.
    const menuIndex = menus.findIndex(
      el =>
        el.node.slug.split("-")[0] === "footer" &&
        el.node.slug.split("-")[2].toLowerCase() === this.props.lang
    )

    const langCode = `/${this.props.lang}`
    const menuItems = menuIndex !== -1 ? menus[menuIndex].node.items : false

    return (
      <nav id="footer-nav">
        <ul>
          {menuItems &&
            menuItems.map(el => {
              const linkDestination =
                el.type !== "custom"
                  ? `${langCode}/${el.object_slug}`
                  : `/${langCode}`
              return (
                <li
                  key={el.title}
                  className={el.classes ? el.classes : undefined}
                >
                  {!isMobile && (
                    <TransitionLink
                      to={linkDestination}
                      dangerouslySetInnerHTML={{ __html: el.title }}
                      exit={pageTransitionExit()}
                      entry={pageTransitionEnter()}
                    />
                  )}
                  {isMobile && (
                    <Link
                      to={linkDestination}
                      dangerouslySetInnerHTML={{ __html: el.title }}
                    />
                  )}
                </li>
              )
            })}
        </ul>
      </nav>
    )
  }
}

export default props => (
  <StaticQuery
    query={graphql`
      query MenuQuery {
        allWordpressWpApiMenusMenusItems {
          edges {
            node {
              wordpress_id
              name
              slug
              name
              items {
                title
                object_id
                object_slug
                url
                type
                classes
              }
            }
          }
        }
      }
    `}
    render={data => <NavFooter data={data} {...props} />}
  />
)
