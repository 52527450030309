import React from "react"
import NavMain from "./Nav-main"
import LangSwitcher from "./LangSwitcher"
import Points from "./Points"
import gsap from "gsap"
import logoDarkMobile from "../images/mobile-logo-dark.svg"
import { Transition } from "react-transition-group"

class Menu extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      visible: false,
      tpl: this.props.tpl,
    }
    this.menuItems = React.createRef()
  }

  toggleMenu() {
    this.setState({
      visible: !this.state.visible,
    })
    this.toggleOverlay()
  }

  toggleBurgerClickability() {
    //document.querySelector(".btn--burger").classList.toggle("disabled")
  }

  toggleOverlay() {
    if (
      this.state.tpl === "TemplateContacts" ||
      this.state.tpl === "TemplateExhibitions"
    ) {
      gsap.to(".page-overlay--body", 0.1, {
        opacity: !this.state.visible ? 0.1 : 0,
      })
    }
  }

  render() {
    const visible = this.state.visible

    return (
      <section id="menu">
        <div className="menu__container">
          <div className="menu__logo sm-show">
            <Transition
              timeout={1000}
              mountOnEnter
              unmountOnExit
              in={visible}
              onEnter={(node, done) => {
                gsap.to(node, 0.6, {
                  delay: 0.7,
                  opacity: visible ? "1" : "0",
                  onComplete: done,
                })
              }}
              onExit={(node, done) => {
                gsap.to(node, 0.6, {
                  opacity: visible ? "1" : "0",
                  onComplete: done,
                })
              }}
            >
              <img src={logoDarkMobile} alt="Neosoft Logo" />
            </Transition>
          </div>
          <div className="grid grid--menu">
            <div className="menu__left">
              <Transition
                timeout={1000}
                mountOnEnter
                unmountOnExit
                in={visible}
                onEnter={(node, done) => {
                  gsap.to(node, 0.6, {
                    delay: 0.85,
                    opacity: visible ? "1" : "0",
                    onComplete: done,
                  })
                }}
                onExit={(node, done) => {
                  gsap.to(node, 0.6, {
                    opacity: visible ? "1" : "0",
                    onComplete: done,
                  })
                }}
              >
                <small>Menu</small>
              </Transition>
              <Transition
                timeout={1000}
                mountOnEnter
                unmountOnExit
                in={visible}
                onEnter={(node, done) => {
                  gsap.to(node, 0.6, {
                    delay: 0.9,
                    opacity: visible ? "1" : "0",
                    onComplete: done,
                  })
                }}
                onExit={(node, done) => {
                  gsap.to(node, 0.6, {
                    opacity: visible ? "1" : "0",
                    onComplete: done,
                  })
                }}
              >
                <LangSwitcher
                  tpl={this.props.tpl}
                  lang={this.props.lang}
                  modifier="menu"
                />
              </Transition>
            </div>
            <div className="menu__right">
              <NavMain lang={this.props.lang} state={this.state.visible} />
            </div>
          </div>

          <Transition
            timeout={1000}
            mountOnEnter
            unmountOnExit
            in={visible}
            onEnter={(node, done) => {
              gsap.to(node, 0.8, {
                opacity: visible ? "1" : "0",
                onComplete: done,
                delay: 0.46,
              })
            }}
            onExit={(node, done) => {
              gsap.to(node, 0.27, {
                opacity: visible ? "1" : "0",
                onComplete: done,
                delay: 0.36,
              })
            }}
          >
            <Points location="menu" theme="dark" />
          </Transition>
          <Transition
            timeout={1000}
            mountOnEnter
            unmountOnExit
            in={visible}
            onEnter={(node, done) => {
              this.toggleBurgerClickability()
              gsap.to(node, 1.2, {
                scaleX: visible ? 1 : 0,
                // x: visible ? "scale3d(1,1,1)" : "scale3d(0,1,1)",
                ease: "power4.inOut",
                onComplete: () => {
                  this.toggleBurgerClickability()
                },
              })
            }}
            onExit={(node, done) => {
              this.toggleBurgerClickability()
              gsap.to(node, 0.75, {
                delay: 0.2,
                scaleX: visible ? 1 : 0,
                // transform: visible ? "scale3d(1,1,1)" : "scale3d(0,1,1)",
                ease: "power4.inOut",
                onComplete: () => {
                  this.toggleBurgerClickability()
                },
              })
            }}
          >
            <div className="menu__bg"></div>
          </Transition>
        </div>
        <div className="page-overlay" />
      </section>
    )
  }
}

export default Menu
