import React from "react"
import { StaticQuery, graphql } from "gatsby"
import logo from "../images/logo.svg"
import NavFooter from "./Nav-footer"
import LangSwitcher from "./LangSwitcher"
import Moment from "moment"
import TransitionLink from "gatsby-plugin-transition-link/AniLink"
import { pageTransitionEnter, pageTransitionExit } from "../animations"
import * as Int from "../interactions"
import { isMobile } from "react-device-detect"
import { Link } from "gatsby"

class Footer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      content: this.props.data.allWordpressPage.nodes[
        this.props.data.allWordpressPage.nodes.findIndex(
          el =>
            el.template === "TemplateHome.php" &&
            el.polylang_current_lang.split("_")[0] === this.props.lang
        )
      ],
    }
    this.footerBgContainer = React.createRef()
    this.canvasRef = React.createRef()
  }

  formCanvas() {
    Int.formCanvas(
      this.state.content.acf.footer.bg_img.localFile.childImageSharp.original
        .src,
      this.footerBgContainer.current,
      this.canvasRef.current
    )
  }

  componentDidMount() {
    this.formCanvas()

    window.addEventListener("resize", () => {
      this.formCanvas()
    })
  }

  render() {
    const footerInfo = this.state.content.acf.footer
    const bgImg = this.state.content.acf.footer.bg_img.localFile.childImageSharp
      .original.src
    const currentYear = new Moment().format("Y")
    const certificateLogos = footerInfo.certificate_logos

    return (
      <footer>
        <div className="footer__container">
          <div className="footer__content">
            <div className="grid">
              <div className="row row--ver-stretch">
                <div className="col lg-6-12 lg-push-left-1-12 md-7-12 sm-6-12">
                  <div className="footer__left">
                    {!isMobile && (
                      <div className="footer__left__up">
                        <TransitionLink
                          className="footer__logo"
                          to={`/${this.props.lang}`}
                          exit={pageTransitionExit()}
                          entry={pageTransitionEnter()}
                        >
                          <img src={logo} alt="logo"></img>
                        </TransitionLink>
                        {certificateLogos && (
                          <div className="footer__certificates certificates certificates--footer footer__certificates--desktop">
                            {certificateLogos.map(certificate => {
                              return (
                                <a
                                  href={certificate.url}
                                  key={certificate.logo.localFile.publicURL}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <img
                                    src={certificate.logo.localFile.publicURL}
                                  />
                                </a>
                              )
                            })}
                          </div>
                        )}
                      </div>
                    )}
                    {isMobile && (
                      <div className="footer-mobile-top">
                        <Link
                          className="footer__logo"
                          to={`/${this.props.lang}`}
                          exit={pageTransitionExit()}
                          entry={pageTransitionEnter()}
                        >
                          <img src={logo} alt="logo"></img>
                        </Link>
                        {certificateLogos && (
                          <div className="footer__certificates certificates certificates--mobile footer__certificates--mobile">
                            {certificateLogos.map(certificate => {
                              return (
                                <a
                                  href={certificate.url}
                                  key={certificate.logo.localFile.publicURL}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <img
                                    src={certificate.logo.localFile.publicURL}
                                  />
                                </a>
                              )
                            })}
                          </div>
                        )}
                      </div>
                    )}

                    <div className="footer__left__lang-switcher xs-hide">
                      <LangSwitcher
                        modifier="footer"
                        lang={this.props.lang}
                        tpl={this.props.tpl}
                      />
                    </div>
                  </div>
                </div>
                <div className="col lg-5-12 md-5-12 sm-6-12">
                  <NavFooter lang={this.props.lang} />
                </div>
                {footerInfo.copyright && (
                  <div className="col lg-10-12 lg-push-left-1-12 sm-12-12">
                    <div className="footer__lower">
                      <p>
                        <span>{footerInfo.copyright}</span>
                        {currentYear} Neosoft
                      </p>
                      <div className="footer__lower__lang-switcher xs-show">
                        <LangSwitcher
                          modifier="footer"
                          lang={this.props.lang}
                          tpl={this.props.tpl}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div
              className="footer__bg"
              ref={this.footerBgContainer}
              style={{ backgroundImage: `url(${bgImg})` }}
            >
              {!isMobile && <canvas id="footer-bg" ref={this.canvasRef} />}
            </div>
          </div>
        </div>
      </footer>
    )
  }
}

export default props => (
  <StaticQuery
    query={graphql`
      query footerQuery {
        allWordpressPage {
          nodes {
            wordpress_id
            template
            polylang_current_lang
            acf {
              footer {
                bg_img {
                  localFile {
                    childImageSharp {
                      original {
                        src
                      }
                    }
                  }
                }
                copyright
                certificate_logos {
                  logo {
                    localFile {
                      publicURL
                    }
                  }
                  url
                }
              }
            }
          }
        }
      }
    `}
    render={data => <Footer data={data} {...props} />}
  />
)
