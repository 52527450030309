import React from "react"

const contactList = props => {
  // console.log(props)

  const contacts = props.data
  return (
    <div className={"contact-list " + props.modifier}>
      <ul>
        {contacts.map(el => {
          let link
          let key
          if (el.type === "email") {
            key = el.email
            link = <a href={"mailto:" + el.email}>{el.email}</a>
          } else {
            key = el.phone
            link = <a href={"tel:" + el.phone}>{el.phone}</a>
          }
          return (
            <li key={key}>
              <label>{el.label} </label>
              {link}
            </li>
          )
        })}
      </ul>
    </div>
  )
}

export default contactList
