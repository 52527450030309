import React from "react"
import Helmet from "react-helmet"
import Header from "../components/header"
import Footer from "../components/Footer"
import Aside from "../components/Aside"
import Menu from "../components/Menu"
import CookieNotice from "../components/CookieNotice"
import Scrollbar from "react-smooth-scrollbar"
import SmoothScrollbar from "smooth-scrollbar"
import SoftScrollPlugin from "../components/Softscroll"
import MobileScroll from "../components/MobileScroll"
import gsap from "gsap"
import ScrollMagic from "scrollmagic"
import * as Animations from "../animations"
import Intro from "../layouts/Intro"
import * as Int from "../interactions"
import * as FakeScrollbar from "../fakeScrollbar"
import Div100vh from "react-div-100vh"
import { isMobile } from "react-device-detect"

SmoothScrollbar.use(MobileScroll)
SmoothScrollbar.use(SoftScrollPlugin)

class PrimaryLayout extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      activeMenu: false,
      animatedIntro: props.intro === "animated" ? true : false,
      parallaxedIntro: props.intro === "parallaxed" ? true : false,
      scrollPositionY: 0,
      limitY: 0,
      tpl: this.props.tpl,
      pageContext: this.props.pageContext,
      scrollbarless: props.scrollbarless === true ? true : false,
      introModifier: this.props.introModifier,
    }
    this.menuElement = React.createRef()
    this.burgerElement = React.createRef()

    this.toggleMenu = this.controlMenu.bind(this)
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {
      return
    }
  }

  componentDidMount() {
    if (!isMobile) {
      if (this.state.scrollbarless === false) {
        // Create fake scrollbar
        FakeScrollbar.createScrollbar()
      }
      const { scrollbar } = this.scrollContainer
      scrollbar.addListener(status => {
        // Move scrollbar
        FakeScrollbar.moveScrollbar()
        // Add scroll event listener
        this.handleScroll(status.offset.y, status.limit.y)
        if (this.state.animatedIntro === true) {
          Animations.animateIntro(status.offset.y, status.limit.y)
        }
        if (this.state.parallaxedIntro === true) {
          Animations.parallaxIntro(status.offset.y)
        }
        if (this.props.tpl === "TemplateContacts") {
          Animations.scrollContacts(status.offset.y)
        }
        if (this.state.tpl === "TemplateOurCompany") {
          Animations.ourCompanyParallax(status.offset.y)
        }
        if (this.state.tpl === "TemplateServiceAndSupport") {
          Animations.serviceSupportParallax(status.offset.y)
        }
        if (isMobile && this.state.tpl === "TemplateHome") {
          scrollbar.addListener(status => {
            gsap.to("#intro.intro--index .intro__bg", 0, {
              y: status.offset.y,
            })
          })
        }
      })
      if (
        this.state.tpl !== "TemplateExhibitions" &&
        this.state.tpl !== "TemplateHome" &&
        this.state.tpl !== "NotFound"
      ) {
        this.footerAnimation(scrollbar)
      }
      this.scrollDown(scrollbar)
    } else {
      const clientHeight = document.documentElement.clientHeight
      const viewportHeight = window.innerHeight

      window.addEventListener("scroll", () => {
        const scrollLimit = document.documentElement.scrollHeight - clientHeight
        this.scrollAnimations(window.scrollY, scrollLimit, viewportHeight)
        this.handleScroll(window.scrollY, scrollLimit)
        if (
          this.state.tpl !== "TemplateExhibitions" &&
          this.state.tpl !== "TemplateHome" &&
          this.state.tpl !== "NotFound"
        ) {
          this.moveFooter()
        }
      })
      this.scrollDown()
    }
  }
  scrollAnimations(scrolledY, documentHeight, viewportHeight) {
    if (this.state.animatedIntro === true) {
      Animations.animateIntro(scrolledY, documentHeight)
    }

    if (this.state.parallaxedIntro === true) {
      Animations.parallaxIntro(scrolledY, viewportHeight)
    }

    if (this.props.tpl === "TemplateContacts") {
      Animations.scrollContacts(scrolledY)
    }

    if (this.state.tpl === "TemplateOurCompany") {
      Animations.ourCompanyParallax(scrolledY)
    }

    if (this.state.tpl === "TemplateServiceAndSupport") {
      Animations.serviceSupportParallax(scrolledY)
    }
  }

  footerAnimation(scrollbar) {
    // Scroll magic

    const controller = new ScrollMagic.Controller()

    new ScrollMagic.Scene({
      triggerElement: ".footer__container",
      triggerHook: 1,
      // offset: -1000,
    })
      .on("enter", e => {
        if (!isMobile) {
          scrollbar.addListener(this.moveFooter)
        }
      })
      .on("leave", e => {
        if (!isMobile) {
          scrollbar.removeListener(this.moveFooter)
        }
      })
      .addTo(controller)
  }

  moveFooter = () => {
    if (!document.querySelector(".footer__content")) return
    const scrolled = this.state.scrollPositionY
    const pageHeight = this.state.limitY
    const viewportHeight = document.documentElement.clientHeight

    const footerHeight = document.querySelector(".footer__content").clientHeight
    const scrolledInFooter = scrolled - (pageHeight - footerHeight)
    // Percentage scrolled inside footer area
    const scrollPercentage =
      ((scrolled - (pageHeight - footerHeight)) / footerHeight) * 100
    // 0% - scale(1.1), 100% - scale(1)
    const scalePercentage = 1.1 - (0.1 / footerHeight) * scrolledInFooter
    // 0% - opacity: 0, 100% - opacity: 1
    const opacityPercentage = scrollPercentage / 100
    // 0% translateY(100px), 100% translateY(0)
    const translatePercentage = scrollPercentage * -1 + 100

    if (isMobile) {
      if (pageHeight - scrolled - viewportHeight < 700) {
        const footerMovement =
          ((pageHeight - scrolled - viewportHeight) * -1) / 3

        gsap.to(".footer__content", 0, {
          y: footerMovement,
        })

        gsap.to(".footer__bg", 0, {
          scale: `${scalePercentage}`,
          force3D: true,
          ease: "none",
        })
      }
    } else {
      gsap.to(".footer__content", 0, {
        y: `${scrollPercentage}%`,
        force3D: true,
        ease: "none",
      })

      gsap.to("footer .grid", 0, {
        opacity: `${opacityPercentage}`,
        y: `${translatePercentage}`,
        force3D: true,
        ease: "none",
      })

      gsap.to(".footer__bg", 0, {
        scale: `${scalePercentage}`,
        force3D: true,
        ease: "none",
      })
    }
  }

  // Update state with current scroll position Y
  handleScroll(position, limit) {
    this.setState({
      scrollPositionY: position,
      limitY: limit,
    })
  }

  // Intro scroll down btn
  scrollDown(scrollbarObj) {
    const introScrollBtn = document.querySelector(".js-scroll-down")
    if (introScrollBtn) {
      introScrollBtn.addEventListener("click", () => {
        Int.scrollDownAnim(scrollbarObj, this.props.introModifier)
      })
    }
  }

  controlMenu() {
    this.menuElement.current.toggleMenu()
    this.burgerElement.current.toggleAside()

    // Part of workaround (more in animations.js)
    gsap.to("#menu", 0, {
      opacity: 1,
    })
  }

  render() {
    if (isMobile) {
      return (
        <div id="app">
          <Helmet title="NeoSoft AG" />
          <div>
            <div className="page-overlay" />
            <div className="page-overlay page-overlay--body" />
            <Header
              modifier={this.props.headerModifier}
              lang={this.props.lang}
              tpl={this.props.tpl}
              theme={this.props.theme}
            />
            <main className={this.props.tpl}>
              {this.props.intro !== false && (
                <Intro
                  pageID={this.state.pageContext.id}
                  modifier={this.props.introModifier}
                  lang={this.props.lang}
                  tpl={this.state.pageContext.templateGT}
                />
              )}
              {this.props.children}
              {!this.props.footerless && <div id="footer-ref"></div>}
            </main>

            {!this.props.footerless && (
              <Footer lang={this.props.lang} tpl={this.props.tpl} />
            )}
          </div>
          <CookieNotice lang={this.props.lang} />
          <Aside
            theme={this.props.theme}
            click={this.toggleMenu}
            ref={this.burgerElement}
            tpl={this.props.tpl}
          />
          <div className="btn btn--burger btn--burger--overlay btn--burger--overlay--0">
            <span className="btn--burger__part btn--burger__part--up"></span>
            <span className="btn--burger__part btn--burger__part--down"></span>
          </div>
          <div className="btn btn--burger btn--burger--overlay btn--burger--overlay--1">
            <span className="btn--burger__part btn--burger__part--up"></span>
            <span className="btn--burger__part btn--burger__part--down"></span>
          </div>
          <div className="btn btn--burger btn--burger--overlay btn--burger--overlay--2">
            <span className="btn--burger__part btn--burger__part--up"></span>
            <span className="btn--burger__part btn--burger__part--down"></span>
          </div>
          <div className="btn btn--burger btn--burger--overlay btn--burger--overlay--3">
            <span className="btn--burger__part btn--burger__part--up"></span>
            <span className="btn--burger__part btn--burger__part--down"></span>
          </div>

          <Menu
            lang={this.props.lang}
            tpl={this.props.tpl}
            visible={this.state.activeMenu}
            ref={this.menuElement}
          />
          {this.state.scrollbarless === false && (
            <>
              <div className="scrollbar-line scrollbar-line--1"></div>
              <div className="scrollbar-line scrollbar-line--2"></div>
              <div className="scrollbar-line scrollbar-line--3"></div>
            </>
          )}
        </div>
      )
    } else {
      return (
        <Div100vh id="app">
          <Helmet title="NeoSoft AG" />
          <Scrollbar
            id="scroll-container"
            damping={isMobile ? 0.1 : 0.09}
            alwaysShowTracks={false}
            ref={c => (this.scrollContainer = c)}
          >
            <div className="page-overlay" />
            <div className="page-overlay page-overlay--body" />
            <Header
              modifier={this.props.headerModifier}
              lang={this.props.lang}
              tpl={this.props.tpl}
              theme={this.props.theme}
            />
            <main className={this.props.tpl}>
              {this.props.intro !== false && (
                <Intro
                  pageID={this.state.pageContext.id}
                  modifier={this.props.introModifier}
                  lang={this.props.lang}
                  tpl={this.state.pageContext.templateGT}
                />
              )}
              {this.props.children}
              {!this.props.footerless && <div id="footer-ref"></div>}
            </main>

            {!this.props.footerless && (
              <Footer lang={this.props.lang} tpl={this.props.tpl} />
            )}
          </Scrollbar>
          <CookieNotice lang={this.props.lang} />
          <Aside
            theme={this.props.theme}
            click={this.toggleMenu}
            ref={this.burgerElement}
            tpl={this.props.tpl}
          />
          <div className="btn btn--burger btn--burger--overlay btn--burger--overlay--0">
            <span className="btn--burger__part btn--burger__part--up"></span>
            <span className="btn--burger__part btn--burger__part--down"></span>
          </div>
          <div className="btn btn--burger btn--burger--overlay btn--burger--overlay--1">
            <span className="btn--burger__part btn--burger__part--up"></span>
            <span className="btn--burger__part btn--burger__part--down"></span>
          </div>
          <div className="btn btn--burger btn--burger--overlay btn--burger--overlay--2">
            <span className="btn--burger__part btn--burger__part--up"></span>
            <span className="btn--burger__part btn--burger__part--down"></span>
          </div>
          <div className="btn btn--burger btn--burger--overlay btn--burger--overlay--3">
            <span className="btn--burger__part btn--burger__part--up"></span>
            <span className="btn--burger__part btn--burger__part--down"></span>
          </div>

          <Menu
            lang={this.props.lang}
            tpl={this.props.tpl}
            visible={this.state.activeMenu}
            ref={this.menuElement}
          />
          {this.state.scrollbarless === false && (
            <>
              <div className="scrollbar-line scrollbar-line--1"></div>
              <div className="scrollbar-line scrollbar-line--2"></div>
              <div className="scrollbar-line scrollbar-line--3"></div>
            </>
          )}
        </Div100vh>
      )
    }
  }
}

export default PrimaryLayout
