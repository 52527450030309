import React from "react"
import { StaticQuery, graphql } from "gatsby"
import IndexContent from "../components/IndexContent"
import * as Int from "../interactions"
import Points from "../components/Points"
import SplitText from "../scripts/SplitText"
import { headingAppear } from "../animations"
import Div100vh from "react-div-100vh"
import { isMobileOnly, isMobile } from "react-device-detect"
import logoWhiteMobile from "../images/mobile-logo.svg"

class Intro extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      data: this.props.data.allWordpressPage.edges[
        this.props.data.allWordpressPage.edges.findIndex(
          element =>
            element.node.wordpress_id === parseInt(this.props.pageID, 10)
        )
      ],
      tpl: this.props.tpl,
      scrollbarObj: this.props.scrollbarObj,
      introImg: this.props.data.allWordpressPage.edges,
    }
    this.introRef = React.createRef()
    this.headingRef = React.createRef()
    this.canvasContainerRef = React.createRef()
    this.canvasRef = React.createRef()
  }

  getMobileImg() {
    if (this.state.data.node.acf.intro.img_mobile !== null) {
      return this.state.data.node.acf.intro.img_mobile.localFile.childImageSharp
        .original.src
    } else {
      return this.state.data.node.acf.intro.img.localFile.childImageSharp
        .original.src
    }
  }

  formCanvas(container, canvas) {
    const desktopImg = this.state.data.node.acf.intro.img.localFile
      .childImageSharp.original.src
    const mobileImg =
      this.state.data.node.acf.intro.img_mobile !== null
        ? this.state.data.node.acf.intro.img_mobile.localFile.childImageSharp
            .original.src
        : false
    let introImg

    if (mobileImg) {
      introImg = isMobileOnly ? mobileImg : desktopImg
    } else {
      introImg = desktopImg
    }

    Int.formCanvas(introImg, container, canvas)
  }

  formHeading(delay) {
    const splitHeadingFirst = new SplitText(this.headingRef.current, {
      type: "lines",
      linesClass: "line line--child",
    })
    const splitHeadingSecond = new SplitText(this.headingRef.current, {
      type: "lines",
      linesClass: "line line--parent",
    })

    headingAppear(delay)

    return [splitHeadingSecond, splitHeadingFirst]
  }

  componentDidMount() {
    const lines = this.formHeading(isMobile ? 0 : 0.8)

    this.formCanvas(this.canvasContainerRef.current, this.canvasRef.current)

    // Repaint canvas on resize

    window.addEventListener("resize", () => {
      lines.forEach(line => {
        line.revert()
      })

      this.formCanvas(this.canvasContainerRef.current, this.canvasRef.current)
    })
    if (isMobile) {
      this.calculateFullHeight()
    }
  }

  calculateFullHeight() {
    const intro = document.querySelector("#intro.intro--inner")

    if (intro) {
      intro.style.height = `${intro.clientHeight}px`
    }
  }

  render() {
    const intro = this.state.data.node

    const introFields = intro.acf.intro

    const introVideo =
      this.state.tpl === "TemplateHome"
        ? intro.acf.intro_video.source_url
        : false

    const imgUrl = introFields.img.localFile.childImageSharp.original.src

    if (this.state.introImg === null) {
      this.setState({
        introImg: imgUrl,
      })
    }

    const pageTitle = intro.title

    const indexContent = intro.acf.content_index

    const contactList = intro.acf.contact_info
      ? intro.acf.contact_info.contact_list
      : false

    const btn = intro.acf.contact_info ? intro.acf.contact_info.btn : false

    const mobileImg = this.getMobileImg()

    return (
      <Div100vh id="intro" className={this.props.modifier}>
        <div className="grid" ref={this.introRef}>
          <div className="intro__heading">
            {this.props.tpl !== "TemplateHome" && (
              <div className="line line--parent">
                <small
                  className="white line line--child"
                  dangerouslySetInnerHTML={{ __html: pageTitle }}
                />
              </div>
            )}
            <h1 id="heading" ref={this.headingRef}>
              {introFields.heading}
            </h1>
          </div>
          {this.props.tpl === "TemplateHome" && (
            <IndexContent
              pageID={this.props.pageID}
              data={indexContent}
              contactList={contactList}
              btn={btn}
              lang={this.props.lang}
              introRef={this.introRef}
            />
          )}
        </div>

        <Points location="intro" modifier={this.props.tpl} />

        <div
          className="intro__bg"
          ref={this.canvasContainerRef}
          style={{
            backgroundImage: `url("${mobileImg}")`,
          }}
        >
          {this.state.tpl !== "TemplateHome" && (
            <canvas id="intro-bg" ref={this.canvasRef} />
          )}
          {this.state.tpl === "TemplateHome" && introVideo && !isMobile && (
            <div
              className="intro__video"
              dangerouslySetInnerHTML={{
                __html: `
              <video
              preload="auto"
              loop
              autoPlay
              muted
              playsInline
            >
              <source src=${introVideo} />
            </video>
              `,
              }}
            />
          )}

          {/* <div
            id="intro-bg"
            style={{
              backgroundImage: `url("${this.state.data.node.acf.intro.img.localFile.childImageSharp.fluid.src}")`,
            }}
          /> */}
        </div>
        {this.state.tpl === "TemplateHome" && (
          <div className="intro-index-logo-mobile">
            <img src={logoWhiteMobile} alt="Logo" />
          </div>
        )}
      </Div100vh>
    )
  }
}

export default props => (
  <StaticQuery
    query={graphql`
      query introQuery {
        allWordpressPage {
          edges {
            node {
              wordpress_id
              title
              acf {
                intro {
                  heading
                  img {
                    localFile {
                      childImageSharp {
                        original {
                          src
                        }
                        # fluid(maxWidth: 1920, quality: 100) {
                        #   src
                        # }
                      }
                    }
                  }
                  img_mobile {
                    localFile {
                      childImageSharp {
                        original {
                          src
                        }
                      }
                    }
                  }
                }
                intro_video {
                  source_url
                }
                content_index {
                  intro_links {
                    post_title
                    post_name
                  }
                  contact_column_title
                  contact_column_text
                  link_button_title
                }
                contact_info {
                  contact_list {
                    label
                    type
                    email
                    phone
                  }
                  btn {
                    title
                    url
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={data => <Intro data={data} {...props} />}
  />
)
