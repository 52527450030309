import React from "react"
import { burgerHover, burgerTransform } from "../animations"

class Aside extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      active: false,
    }
  }

  toggleAside() {
    this.setState({
      active: !this.state.active,
    })
  }

  toggleMenu(e) {
    this.props.click(e)

    const burgers = document.querySelectorAll(".btn--burger")
    const burger = e.target.closest("button")

    burgers.forEach(el => {
      el.classList.toggle("active")
    })
    const state = burger.classList.contains("active")
      ? "toActive"
      : "toInactive"
    burgerTransform(burger, state)
  }

  render() {
    const themeClass = this.props.theme === "white" ? "aside--dark" : ""
    const activeClass = this.state.active === true ? "active" : ""

    const asideClasses = `${themeClass} ${activeClass}`

    return (
      <aside className={asideClasses}>
        <button
          className={`btn btn--burger btn--blank menu-toggle`}
          onClick={e => this.toggleMenu(e)}
          onMouseEnter={e => {
            burgerHover("in")
          }}
          onMouseLeave={e => {
            burgerHover("out")
          }}
        >
          <span className="btn--burger__part btn--burger__part--up"></span>
          <span className="btn--burger__part btn--burger__part--down"></span>
        </button>
      </aside>
    )
  }
}

export default Aside
