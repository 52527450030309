import React from "react"
import { StaticQuery, graphql } from "gatsby"
import { Transition } from "react-transition-group"
import TransitionLink from "gatsby-plugin-transition-link/AniLink"
import gsap from "gsap"
import { pageTransitionEnter, pageTransitionExit } from "../animations"
import { isMobile } from "react-device-detect"
import { Link } from "gatsby"

export class NavMain extends React.Component {
  render() {
    const menus = this.props.data.allWordpressWpApiMenusMenusItems.edges

    // Expected name is for example main-menu-en, footer-menu-de, etc.
    const menuIndex = menus.findIndex(
      el =>
        el.node.slug.split("-")[0] === "main" &&
        el.node.slug.split("-")[2].toLowerCase() === this.props.lang
    )

    const langCode = `/${this.props.lang}`
    const menuItems = menuIndex !== -1 ? menus[menuIndex].node.items : false

    const visible = this.props.state

    return (
      <Transition
        timeout={1000}
        mountOnEnter
        unmountOnExit
        in={visible}
        onEnter={(node, done) => {
          const delay = 0.5
          const duration = 0.75
          const stagger = 0.1

          gsap.fromTo(
            " #menu .line--child",
            {
              yPercent: 26,
              opacity: 0,
              scaleY: 1 / 3,
            },
            {
              yPercent: -25,
              opacity: 1,
              scaleY: 1 / 3,
              duration: duration,
              stagger: stagger,
              delay: delay,
              onComplete: done,
            }
          )
          gsap.fromTo(
            "#menu .line--parent",
            {
              webkitMaskImage:
                "-webkit-linear-gradient(bottom, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 33%, rgba(0,0,0,1) 70%,  rgba(0,0,0,1) 100%)",
              y: 0,
              scaleY: 3,
            },
            {
              webkitMaskImage:
                "-webkit-linear-gradient(bottom, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 33%, rgba(0,0,0,1) 70%,  rgba(0,0,0,1) 100%)",
              yPercent: 100,
              scaleY: 3,
              duration: duration,
              stagger: stagger,
              delay: delay,
              onComplete: done,
            }
          )
        }}
        onExit={(node, done) => {
          gsap.to(node, 0.5, {
            opacity: visible ? "1" : "0",
            onComplete: done,
          })
        }}
      >
        <nav id="main-nav">
          <ul>
            {menuItems &&
              menuItems.map(el => {
                const linkDestination =
                  el.type !== "custom"
                    ? `${langCode}/${el.object_slug}`
                    : `/${langCode}`
                return (
                  <li
                    key={el.title}
                    className={el.classes ? el.classes : undefined}
                  >
                    <div className="link-main-container line line--parent">
                      {!isMobile && (
                        <TransitionLink
                          className="link-main line line--child"
                          to={linkDestination}
                          dangerouslySetInnerHTML={{ __html: el.title }}
                          exit={pageTransitionExit()}
                          entry={pageTransitionEnter()}
                        />
                      )}
                      {isMobile && (
                        <Link
                          className="link-main line line--child"
                          to={linkDestination}
                          dangerouslySetInnerHTML={{ __html: el.title }}
                        />
                      )}
                    </div>
                  </li>
                )
              })}
          </ul>
        </nav>
      </Transition>
    )
  }
}

export default props => (
  <StaticQuery
    query={graphql`
      query MainMenuQuery {
        allWordpressWpApiMenusMenusItems {
          edges {
            node {
              wordpress_id
              name
              slug
              name
              items {
                title
                object_id
                object_slug
                url
                type
                classes
              }
            }
          }
        }
      }
    `}
    render={data => <NavMain data={data} {...props} />}
  />
)
