import React from "react"
import { StaticQuery, graphql } from "gatsby"
import TransitionLink from "gatsby-plugin-transition-link/AniLink"
import { pageTransitionEnter, pageTransitionExit } from "../animations"
import * as int from "../interactions"
import { isMobile } from "react-device-detect"
import { Link } from "gatsby"

export default props => {
  const lang = props.lang
  const tpl = props.tpl

  return (
    <StaticQuery
      query={graphql`
        query langSwitcherQuery {
          allSitePage {
            nodes {
              path
              context {
                templateWP
                templateGT
                lang
              }
              componentPath
            }
          }
        }
      `}
      render={data => {
        const allPages = data.allSitePage.nodes

        class Translation {
          constructor(tpl, path, lang) {
            this.tpl = tpl
            this.path = path
            this.lang = lang
          }
        }

        const pageTranslations = []

        allPages.forEach(pg => {
          if (pg.context && pg.context.templateGT === tpl) {
            pageTranslations.push(
              new Translation(pg.context.templateGT, pg.path, pg.context.lang)
            )
          }
        })

        return (
          <div
            id="lang-switcher"
            className={`lang-switcher lang-switcher--${props.modifier}`}
          >
            {pageTranslations.length > 0 && (
              <ul>
                {pageTranslations.map(el => {
                  return (
                    <li
                      className={el.lang === lang ? "active" : undefined}
                      key={el.lang}
                    >
                      {!isMobile && (
                        <TransitionLink
                          to={el.path}
                          exit={pageTransitionExit()}
                          entry={pageTransitionEnter()}
                          data-lang={el.lang}
                          onClick={e => int.setLangCookie(e)}
                        >
                          {el.lang}
                        </TransitionLink>
                      )}
                      {isMobile && (
                        <Link
                          to={el.path}
                          data-lang={el.lang}
                          onClick={e => int.setLangCookie(e)}
                        >
                          {el.lang}
                        </Link>
                      )}
                    </li>
                  )
                })}
              </ul>
            )}
          </div>
        )
      }}
    />
  )
}
