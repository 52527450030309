import gsap from "gsap"
import { isMobile } from "react-device-detect"

const pageTransitionExit = () => {
  return {
    zIndex: 100,
    trigger: ({ exit, node }) => {
      const activeBurger = document.querySelector(".btn--burger.active")
        ? true
        : false

      const menuDelay = document.querySelector(".btn--burger.active")
        ? 0.6
        : 0.5

      const menuTimeline = gsap.timeline()
      gsap.fromTo(
        node,
        {
          height: "100vh",
          overflowY: "hidden",
        },
        {
          height: "0",
          ease: "power4.inOut",
          duration: 1.3,
        }
      )
      gsap.to(".page-overlay--body", 0.1, {
        opacity: 0,
      })
      // Since menu is fixed, it's ignored by transition animation.
      // This workaround moves menu same as the page transition and then
      // brings it down invisible. Opacity is set back to 1 in layout.js

      if (activeBurger) {
        menuTimeline.to(".btn--burger", {
          opacity: 0,
          duration: 0.3,
        })

        menuTimeline.to(".btn--burger__part", {
          width: 0,
          duration: 0,
          delay: 0.3,
        })
      } else {
        menuTimeline.fromTo(
          ".btn--burger__part--up",
          {
            right: "5px",
            left: "unset",
          },
          {
            duration: 0.3,
            width: 0,
            ease: "Power3.easeIn",
          }
        )
        menuTimeline.fromTo(
          ".btn--burger__part--down",
          {
            right: "5px",
            left: "unset",
          },
          {
            duration: 0.3,
            width: 0,
            ease: "Power3.easeIn",
          },
          "-=0.1"
        )
      }

      menuTimeline.to(
        "#menu",
        {
          duration: 1.3,
          height: 0,
          ease: "power4.inOut",
        },
        `-=${menuDelay}`
      )
      menuTimeline.to("#menu", {
        duration: 0,
        opacity: 0,
      })
      menuTimeline.to("#menu", {
        duration: 0,
        height: "100vh",
      })
    },
    length: 1,
  }
}

const pageTransitionEnter = () => {
  return {
    zIndex: 99,
    delay: 0,
    trigger: ({ entry, node }) => {
      gsap.fromTo(
        `.tl-wrapper-status--exited .intro__bg`,
        {
          yPercent: 30,
        },
        {
          yPercent: 0,
          duration: 1.5,
          delay: 0.2,
          ease: "Power4.easeOut",
        }
      )

      gsap.to(".btn--burger", {
        opacity: 1,
        duration: 0,
        delay: 1.2,
      })
      gsap.fromTo(
        ".btn--burger__part--up",
        {
          left: "5px",
          right: "unset",
        },
        {
          duration: 0.5,
          width: "30px",
          delay: 1.2,
          ease: "Power3.easeOut",
        }
      )
      gsap.fromTo(
        ".btn--burger__part--down",
        {
          left: "5px",
          right: "unset",
        },
        {
          duration: 0.5,
          width: "30px",
          delay: 1.4,
          ease: "Power3.easeOut",
        }
      )
    },
  }
}

const pageTransitionExitLangSelect = () => {
  return {
    zIndex: 100,
    trigger: ({ exit, node }) => {
      const timeLine = gsap.timeline()

      timeLine.to(".overlay--title-container", 1, {
        yPercent: 50,
        force3D: true,
      })
      timeLine.to(
        ".js-lang-link",
        0.7,
        {
          x: 20,
          opacity: 0,
          pointerEvents: "initial",
          force3D: true,
          stagger: index => {
            return index * 0.1
          },
        },
        "<0.2"
      )
      timeLine.fromTo(
        node,
        {
          opacity: 1,
        },
        {
          opacity: 0,
          duration: 0.8,
        },
        "-=0.4"
      )
      timeLine.to("#lang-select", 0, {
        visibility: "hidden",
      })
      timeLine.fromTo(
        ".intro__bg",
        {
          scale: 1.3,
        },
        {
          scale: 1,
          duration: 1.2,
          ease: "Power1.easeOut",
          force3D: true,
        },
        "-=1"
      )
      timeLine.fromTo(
        ".intro__heading",
        {
          y: 20,
          opacity: 0,
        },
        {
          y: 0,
          opacity: 1,
          duration: 1,
        },
        "-=0.4"
      )
      timeLine.fromTo(
        ".intro__content__links__border",
        {
          scaleY: 0,
        },
        {
          scaleY: 1,
          stagger: 0.15,
          duration: 0.7,
        },
        "-=0.6"
      )
      timeLine.fromTo(
        ".intro_content__link__border--anim",
        {
          y: -20,
          opacity: 0,
        },
        {
          y: 0,
          opacity: 1,
          duration: 0.7,
        },
        "-=0.6"
      )
      timeLine.fromTo(
        ".intro__link__title--anim",
        {
          y: -20,
          opacity: 0,
        },
        {
          y: 0,
          opacity: 1,
          stagger: 0.2,
          duration: 0.7,
        },
        "-=0.5"
      )

      timeLine.fromTo(
        ".intro__content__contacts-anim",
        {
          y: -20,
          opacity: 0,
        },
        {
          y: 0,
          opacity: 1,
          duration: 0.8,
        },
        "-=0.8"
      )
      timeLine.fromTo(
        ".cta__right__btn ",
        {
          y: -20,
          opacity: 0,
        },
        {
          y: 0,
          opacity: 1,
          duration: 0.8,
        },
        "-=0.4"
      )
      // timeLine.fromTo(
      //   ".btn--burger__part--up ",
      //   {
      //     width: 0,
      //   },
      //   {
      //     width: 30,
      //     duration: 0.5,
      //   },
      //   "-=0.8"
      // )
      // timeLine.fromTo(
      //   ".btn--burger__part--down ",
      //   {
      //     width: 0,
      //   },
      //   {
      //     width: 30,
      //     duration: 0.4,
      //   },
      //   "-=0.6"
      // )
      timeLine.fromTo(
        ".btn--burger__part--up ",
        {
          scaleX: 0,
        },
        {
          scaleX: 1,
          duration: 0.5,
        },
        "-=0.8"
      )
      timeLine.fromTo(
        ".btn--burger__part--down ",
        {
          scaleX: 0,
        },
        {
          scaleX: 1,
          duration: 0.4,
        },
        "-=0.6"
      )
    },
    length: 2,
  }
}

const appearAnimation = (target, delay = null) => {
  gsap.to(target, 1, {
    y: 0,
    delay: delay,
  })
  gsap.to(target, 2, {
    opacity: 1,
    visibility: "visible",
    delay: delay,
  })
}

const exhibitionAnimationIn = (target, delay = null, done = false) => {
  gsap.fromTo(
    target,
    {
      opacity: 0,
      y: 20,
    },
    {
      y: 0,
      opacity: 1,
      delay: delay,
    }
  )
}

const exhibitionAnimationOut = (target, delay = null, done = false) => {
  gsap.to(target, 0.4, {
    opacity: 0,
    y: -20,
    delay: delay,
    onComplete: done,
  })
}

const animateIntro = (scrolled, viewportHeight) => {
  if (scrolled < 1000) {
    const scaleCoef = 1 - (scrolled / viewportHeight) * 0.1 + 0.15
    const opacityCoefContent = 1 - scrolled / viewportHeight
    const opacityCoefImg = 1 - (scrolled / viewportHeight) * 0.5
    const translateCoefImg = scrolled / 3
    const translateCoefContent = scrolled / 6
    gsap.to(".intro__bg", 0, {
      force3D: true,
      y: `${translateCoefImg}`,
      scale: `${!isMobile ? scaleCoef : 1}`,
      opacity: `${!isMobile ? opacityCoefImg : 1}`,
      ease: "none",
    })
    gsap.to("#intro .grid", 0, {
      force3D: true,
      opacity: `${opacityCoefContent}`,
      y: `${translateCoefContent}`,
      ease: "none",
    })
  }
}

const parallaxIntro = scrolled => {
  if (scrolled < 2000) {
    const viewportHeight = window.innerHeight
    const opacityCoefContent = 1 - (scrolled / viewportHeight) * 0.5
    //const opacityCoefImg = 1 - (scrolled / viewportHeight) * 0.5
    const translateCoefImg = scrolled * 0.25
    gsap.to(".intro__bg", 0, {
      force3D: true,
      y: `${translateCoefImg}`,
      transform: "translateZ(0)",
      ease: "none",
    })
    gsap.to("#intro .grid", 0, {
      force3D: true,
      opacity: `${opacityCoefContent}`,
      ease: "none",
    })
  }
}

const scrollContacts = scrolled => {
  if (scrolled > 0) {
    const main = document.querySelector("main")
    const intro = document.querySelector("#intro")

    const pageHeight = main.clientHeight
    const introHeight = intro.clientHeight

    // console.log(introHeight)

    const rotateCoef = scrolled / 50 + 40

    const scaleCoef = 1 + (scrolled / 5000) * -1
    gsap.to(".contacts-bg-container", 0, {
      y: isMobile ? 0 : scrolled,
    })
    gsap.to("#contacts-bg", 0, {
      rotateX: rotateCoef,
      // z: translateCoef,
      scale: scaleCoef,
      force3D: true,
      ease: "none",
    })
    if (!isMobile) {
      if (scrolled > introHeight / 2) {
        const opacityCoef =
          1 - ((scrolled - introHeight / 2) / pageHeight) * 3.3
        gsap.to("#contacts-bg", 0, {
          opacity: `${opacityCoef}`,
          ease: "none",
        })
      } else {
        gsap.to("#contacts-bg", 0.5, {
          opacity: 1,
          ease: "none",
        })
      }
    } else {
      const opacityCoef = 1 - ((scrolled - introHeight / 2) / pageHeight) * 5
      gsap.to("#contacts-bg", 0, {
        opacity: `${opacityCoef}`,
        ease: "none",
      })
    }
  }
}

const ourCompanyParallax = scrolled => {
  const bgScrollCoef = scrolled * 0.18
  const textScrollCoaf = scrolled * 0.05 * -1

  gsap.to(".manifest__bg", 0, {
    y: `${bgScrollCoef}`,
    force3D: true,
    ease: "none",
  })
  gsap.to(".mission__content", 0, {
    y: `${textScrollCoaf}`,
    force3D: true,
    ease: "none",
  })
}

const paintScrollbar = (template, scrolled) => {
  switch (template) {
    case "TemplateServiceAndSupport":
      const introHeight = document.querySelector("#intro").clientHeight
      if (scrolled >= introHeight - 100) {
        document.querySelector(".scrollbar-track-y").classList.add("changing")
      } else {
        document
          .querySelector(".scrollbar-track-y")
          .classList.remove("changing")
      }

      break

    default:
      break
  }
}

const bubble = (bubble, parent) => {
  parent.style.pointerEvents = "none"
  setTimeout(() => {
    parent.style.pointerEvents = "initial"
  }, 1500)
  bubble.style.display = "block"
  gsap.fromTo(
    bubble,
    1.5,
    {
      width: 0,
      height: 0,
      visibility: "visible",
      opacity: 1,
    },
    {
      width: "120px",
      height: "120px",
      visibility: "visible",
      opacity: 0,
    }
  )
}

const hideGreetings = (element, delay) => {
  //const delay = 0.5
  const overlayUp = element.childNodes[1]
  const overlayDown = element.childNodes[2]

  gsap.to(overlayUp, delay, {
    y: 100,
    force3D: true,
  })
  gsap.to(element, 0, {
    zIndex: 0,
    delay: delay,
  })
  gsap.to(overlayUp, 0, {
    y: -100,
    delay: delay,
  })
  gsap.to(overlayDown, 0, {
    y: 0,
    delay: delay,
  })
  gsap.to(element, 0, {
    x: -10,
    delay: delay,
  })
}

const hideGreetingTitle = () => {
  gsap.to(".left__title--main .overlay--hide", 0.7, {
    x: 0,
    // force3D: true,
  })
}

const showGreeting = (element, delay) => {
  //const delay = 0.4
  const overlayDown = element.childNodes[2]
  gsap.to(element, 0, {
    zIndex: 1,
    delay: delay,
  })
  gsap.to(element, 0.7, {
    x: 0,
    force3D: true,
    delay: delay,
    ease: "Power2.easeOut",
  })
  gsap.to(overlayDown, 0.7, {
    y: 100,
    force3D: true,
    delay: delay,
    ease: "Power3.easeOut",
  })
}

const changeTextColor = (text, color) => {
  gsap.to(text, 0.1, {
    color: color,
  })
}

const preloadFadeOut = tpl => {
  const timeLine = gsap.timeline()

  if (tpl === "langSelect") {
    timeLine.eventCallback("onStart", showLangSelectLogo, [1])
  }

  timeLine.to(".point--preload", 0.3, {
    opacity: 0,
    delay: 0.4,
    stagger: function(index) {
      return index * 0.015
    },
  })

  timeLine.to(
    ".preload__logo .overlay",
    1,
    {
      x: 0,
      force3D: true,
    },
    "<0.05"
  )
  timeLine.to(".preload__logo", 0, {
    visibility: "hidden",
  })
  if (tpl === "langSelect") {
    timeLine.eventCallback("onComplete", showLangSelect)
  }
}

const showLangSelectLogo = delay => {
  gsap.to(".lang-select__logo .overlay", 1, {
    xPercent: 50,
    force3D: true,
    delay: delay,
  })
}

const showLangSelect = () => {
  const timeLine = gsap.timeline()

  timeLine.to(".left__title--main", 1, {
    x: 0,
    force3D: true,
  })
  timeLine.to(
    ".left__title--main .overlay--up",
    1,
    {
      yPercent: 50,
      force3D: true,
    },
    "<"
  )
  timeLine.to(
    ".js-lang-link",
    0.7,
    {
      x: 0,
      opacity: 1,
      pointerEvents: "initial",
      force3D: true,
      stagger: index => {
        return index * 0.07
      },
    },
    "<0.2"
  )
  timeLine.to(
    ".lang-select__separator__line",
    0.5,
    {
      scaleY: 1,
      force3D: true,
    },
    "<"
  )
}

const headingAppear = delay => {
  const duration = 1.5
  const stagger = 0.5

  gsap.fromTo(
    // childLines,
    ".tl-wrapper:not(.tl-wrapper-status--entered) .line--child",
    {
      yPercent: 26,
      opacity: 0,
      scaleY: 1 / 3,
    },
    {
      yPercent: -25,
      opacity: 1,
      scaleY: 1 / 3,
      duration: duration,
      stagger: stagger,
      delay: delay,
      //force3D: true,
    }
  )
  gsap.fromTo(
    // parentLines,
    ".tl-wrapper:not(.tl-wrapper-status--entered) .line--parent",
    {
      webkitMaskImage:
        "-webkit-linear-gradient(bottom, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 33%, rgba(0,0,0,1) 70%,  rgba(0,0,0,1) 100%)",
      y: 0,
      scaleY: 3,
    },
    {
      webkitMaskImage:
        "-webkit-linear-gradient(bottom, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 33%, rgba(0,0,0,1) 70%,  rgba(0,0,0,1) 100%)",
      yPercent: 100,
      scaleY: 3,
      duration: duration,
      stagger: stagger,
      delay: delay,
    }
  )
}

const serviceSupportParallax = scrolled => {
  gsap.to(".js-service-img", {
    y: scrolled / -15,
  })
  gsap.to(".js-service-img-container", {
    y: scrolled / -25,
  })
}

const burgerHover = state => {
  if (!isMobile) {
    const burgers = document.querySelectorAll(".btn--burger")
    if (state === "in") {
      burgers.forEach(el => {
        el.classList.add("hovered")
      })
      gsap.to(".btn--burger__part", 0, {
        transformOrigin: "center",
      })
      gsap.to(".btn--burger:not(.active) .btn--burger__part--up", 0.3, {
        y: 1,
        scaleX: 1.2,
        z: 0,
      })
      gsap.to(".btn--burger:not(.active) .btn--burger__part--down", 0.3, {
        y: -1,
        scaleX: 1.2,
        z: 0,
      })
    }
    if (state === "out") {
      burgers.forEach(el => {
        el.classList.remove("hovered")
      })
      gsap.to(".btn--burger:not(.active) .btn--burger__part--up", 0.3, {
        y: 0,
        scaleX: 1,
        // width: "36px",
      })
      gsap.to(".btn--burger:not(.active) .btn--burger__part--down", 0.3, {
        y: 0,
        scaleX: 1,
      })
    }
  }
}

const burgerTransform = (burger, state) => {
  gsap.to(".btn--burger__part", 0, {
    transformOrigin: "center",
  })
  if (state === "toActive") {
    gsap.to(".btn--burger__part--up", 0.45, {
      x: 4,
      y: isMobile ? 4 : 5,
      rotate: 45,
    })

    gsap.to(".btn--burger__part--down", 0.45, {
      x: 4,
      y: -5,
      rotate: -45,
    })
  } else {
    gsap.to(".btn--burger__part--up", 0.45, {
      x: 0,
      y: burger.classList.contains("hovered") ? 1 : 0,
      rotate: 0,
    })

    gsap.to(".btn--burger__part--down", 0.45, {
      x: 0,
      y: burger.classList.contains("hovered") ? -1 : 0,
      rotate: 0,
    })
  }
}

export {
  pageTransitionEnter,
  pageTransitionExit,
  appearAnimation,
  animateIntro,
  parallaxIntro,
  scrollContacts,
  ourCompanyParallax,
  paintScrollbar,
  exhibitionAnimationIn,
  exhibitionAnimationOut,
  bubble,
  showGreeting,
  hideGreetings,
  pageTransitionExitLangSelect,
  changeTextColor,
  preloadFadeOut,
  showLangSelect,
  hideGreetingTitle,
  headingAppear,
  serviceSupportParallax,
  burgerHover,
  burgerTransform,
}
