import React from "react"
import ContactList from "./ContactList"
import TransitionLink from "gatsby-plugin-transition-link/AniLink"
import CTABtn from "./CTABtn"
import { pageTransitionEnter, pageTransitionExit } from "../animations"
import Scrollbar from "react-smooth-scrollbar"
import * as FakeScrollbar from "../fakeScrollbar"
import { isMobile } from "react-device-detect"
import { Link } from "gatsby"

class IndexContent extends React.Component {
  componentDidMount() {
    const { scrollbar } = this.scrollContainer

    scrollbar.addListener(status => {
      FakeScrollbar.moveScrollbar()
    })

    if (isMobile) {
      scrollbar.destroy()
    }
  }

  render() {
    const introContent = this.props.data
    const introLinks = introContent.intro_links
    const linkButtonTitle = introContent.link_button_title

    const contactLinks = this.props.contactList

    const urlLang = this.props.lang
    let linkCount = 0

    return (
      <div className="intro__content">
        <Scrollbar
          id="intro-scroll-container"
          className="intro__content__links"
          damping={0.08}
          alwaysShowTracks={true}
          wheelEventTarget={this.props.introRef.current}
          ref={c => (this.scrollContainer = c)}
        >
          <div className="intro__content__links__container">
            {introLinks.map(el => {
              linkCount++
              if (isMobile) {
                return (
                  <Link
                    to={`/${urlLang}/${el.post_name}`}
                    key={el.post_name}
                    className={
                      linkCount !== 1 && linkCount !== 2 ? "bordered" : ""
                    }
                  >
                    <div className="intro__content__link__wrap">
                      <small
                        className={`intro__link__title intro__link__title${
                          linkCount === 1 || linkCount === 2 || isMobile
                            ? "--anim"
                            : ""
                        }`}
                        dangerouslySetInnerHTML={{ __html: el.post_title }}
                      />
                      <div className="btn-container fading-text">
                        <span className="btn btn--arrow btn--arrow--white">
                          {linkButtonTitle}
                        </span>
                      </div>
                    </div>
                    <div
                      className={`intro_content__link__border intro_content__link__border${
                        linkCount === 1 || isMobile ? "--anim" : ""
                      }`}
                    />
                  </Link>
                )
              } else {
                return (
                  <TransitionLink
                    exit={pageTransitionExit()}
                    entry={pageTransitionEnter()}
                    to={`/${urlLang}/${el.post_name}`}
                    key={el.post_name}
                    className={
                      linkCount !== 1 && linkCount !== 2 ? "bordered" : ""
                    }
                  >
                    <div className="intro__content__link__wrap">
                      <small
                        className={`intro__link__title intro__link__title${
                          linkCount === 1 || linkCount === 2 || isMobile
                            ? "--anim"
                            : ""
                        }`}
                        dangerouslySetInnerHTML={{ __html: el.post_title }}
                      />
                      <div className="btn-container fading-text">
                        <span className="btn btn--arrow btn--arrow--white">
                          {linkButtonTitle}
                        </span>
                      </div>
                    </div>
                    <div
                      className={`intro_content__link__border intro_content__link__border${
                        linkCount === 1 || isMobile ? "--anim" : ""
                      }`}
                    />
                  </TransitionLink>
                )
              }
            })}
            <div className="point point--top point--top--left md-show" />
            <div className="point point--top point--top--right md-show" />
            <div className="point point--bottom point--bottom--left md-show" />
            <div className="point point--bottom point--bottom--right md-show" />
          </div>
          <div className="intro__content__links__border intro__content__links__border--left md-hide" />
          <div className="intro__content__links__border intro__content__links__border--right md-hide" />
          <div className="intro__content__links__border intro__content__links__border--top md-show" />
        </Scrollbar>
        <div className="intro__content__contacts">
          <div className="intro__content__contacts-anim">
            {introContent.contact_column_title && (
              <small>{introContent.contact_column_title}</small>
            )}
            {introContent.contact_column_text && (
              <p>{introContent.contact_column_text}</p>
            )}
            <ContactList
              modifier="contact-list--white contact-list--small"
              data={contactLinks}
              lang={this.props.lang}
            />
          </div>

          <CTABtn color="white" btn={this.props.btn} lang={this.props.lang} />
        </div>
      </div>
    )
  }
}

export default IndexContent
