import Cookies from "universal-cookie"
import { isMobile } from "react-device-detect"
import * as Scroll from "react-scroll"

const scroller = Scroll.animateScroll

// Index intro link scroll
export const introScroll = e => {
  const isMobile = true
  if (isMobile === false) {
    document.querySelector(".intro__content__links").scrollTop += e.deltaY
  }
}

// Scrolldown

export const scrollDownAnim = (scrollbarObj, intro) => {
  let scrollDistance
  const scrollTarget = document.querySelector(".js-scroll-target")

  if (isMobile) {
    scrollDistance = scrollTarget.getBoundingClientRect().top - 200

    scroller.scrollMore(scrollDistance)
  } else {
    const scrollTargetHeight = scrollTarget.clientHeight
    scrollDistance =
      scrollTarget.getBoundingClientRect().top -
      window.innerHeight / 2 +
      scrollTargetHeight / 2
    scrollbarObj.scrollTo(0, scrollDistance, 1200)
  }
}

export const setLangCookie = e => {
  const cookies = new Cookies()

  const link = e.target.closest("a")
  const lang = link.dataset.lang

  cookies.set("lang", lang, { path: "/" })
}

function drawImageProp(ctx, img, x, y, w, h, offsetX, offsetY) {
  if (arguments.length === 2) {
    x = y = 0
    w = ctx.canvas.width
    h = ctx.canvas.height
  }

  // default offset is center
  offsetX = typeof offsetX === "number" ? offsetX : 0.5
  offsetY = typeof offsetY === "number" ? offsetY : 0

  // keep bounds [0.0, 1.0]
  if (offsetX < 0) offsetX = 0
  if (offsetY < 0) offsetY = 0
  if (offsetX > 1) offsetX = 1
  if (offsetY > 1) offsetY = 1

  var iw = img.width,
    ih = img.height,
    r = Math.min(w / iw, h / ih),
    nw = iw * r, // new prop. width
    nh = ih * r, // new prop. height
    cx,
    cy,
    cw,
    ch,
    ar = 1

  // decide which gap to fill
  if (nw < w) ar = w / nw
  if (Math.abs(ar - 1) < 1e-14 && nh < h) ar = h / nh // updated
  nw *= ar
  nh *= ar

  // calc source rectangle
  cw = iw / (nw / w)
  ch = ih / (nh / h)

  cx = (iw - cw) * offsetX
  cy = (ih - ch) * offsetY

  // make sure source rectangle is valid
  if (cx < 0) cx = 0
  if (cy < 0) cy = 0
  if (cw > iw) cw = iw
  if (ch > ih) ch = ih

  // fill image in dest. rectangle
  ctx.imageSmoothingQuality = "high"
  ctx.drawImage(img, cx, cy, cw, ch, x, y, w, h)
}

export const formCanvas = (imgUrl, container, canvas) => {
  // const canvas = document.getElementById(canvasID),

  if (canvas !== null) {
    const context = canvas.getContext("2d", { alpha: false })

    const width = container.clientWidth
    const height = container.clientHeight
    canvas.width = width
    canvas.height = height

    const setCanvasImg = () => {
      const imgForCanvas = new Image()
      imgForCanvas.src = imgUrl

      imgForCanvas.onload = () => {
        drawImageProp(context, imgForCanvas, 0, 0, width, height)
      }
    }

    setCanvasImg()
  }
}
