import React from "react"
import * as Buttons from "./Buttons"

export default props => {
  const btn = props.btn

  const btnUrl = `/${props.lang}/${btn.url}`

  return (
    <div className="cta__right__btn">
      {Buttons.primary(props.color, btnUrl, btn.title, btn.target)}
    </div>
  )
}
