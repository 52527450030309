import gsap from "gsap"

const getCoef = () => {
  const vh = Math.max(
    document.documentElement.clientHeight || 0,
    window.innerHeight || 0
  )

  const margin = getWindowWidth() > 1440 ? 168 : 128

  return (vh - margin) / vh
}

const getWindowWidth = () => {
  const vw = Math.max(
    document.documentElement.clientWidth || 0,
    window.innerWidth || 0
  )
  return vw
}

const createScrollbar = () => {
  for (let i = 0; i < 4; i++) {
    setTimeout(() => {
      const scrollbarHeightRaw = document.querySelector(
        ".scrollbar-track .scrollbar-thumb-y"
      ).style.height

      const scrollHandleHeightFull = parseInt(
        scrollbarHeightRaw.substring(0, scrollbarHeightRaw.length - 2)
      )

      const scrollHandleClone = document
        .querySelector(".scrollbar-track .scrollbar-thumb-y")
        .cloneNode()

      scrollHandleClone.style.height = `${scrollHandleHeightFull * getCoef()}px`

      scrollHandleClone.classList.add("clone")

      scrollHandleClone.classList.add(`clone--${i + 1}`)

      document
        .querySelector("#app")
        .insertBefore(scrollHandleClone, document.querySelector("#menu"))
    }, 5)
  }
}

const moveScrollbar = () => {
  const originalScrollbarTransform = document.querySelector(
    ".scrollbar-track .scrollbar-thumb-y"
  ).style.transform

  const originalScrollbarTransformInt = parseInt(
    originalScrollbarTransform.substring(
      originalScrollbarTransform.indexOf(",") + 2,
      originalScrollbarTransform.lastIndexOf(",") - 2
    )
  )

  gsap.to(".scrollbar-thumb-y.clone", 0, {
    y: originalScrollbarTransformInt * getCoef(),
  })
}

export { createScrollbar, getCoef, moveScrollbar }
