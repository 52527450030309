import React from "react"
import logoWhite from "../images/logo.svg"
import logoDark from "../images/logo-dark.svg"
import logoWhiteMobile from "../images/mobile-logo.svg"
import logoDarkMobile from "../images/mobile-logo-dark.svg"
import TransitionLink from "gatsby-plugin-transition-link/AniLink"
import { pageTransitionEnter, pageTransitionExit } from "../animations"
import { isMobile } from "react-device-detect"
import { Link } from "gatsby"

const header = props => {
  const logo = props.theme === "white" ? logoDark : logoWhite
  const logoMobile = props.theme === "white" ? logoDarkMobile : logoWhiteMobile

  return (
    <header className={props.modifier}>
      <div className="grid">
        <div className="header__container">
          {!isMobile && (
            <TransitionLink
              className={`link header__logo header__logo--${props.tpl}`}
              to={`${props.lang}`}
              exit={pageTransitionExit()}
              entry={pageTransitionEnter()}
            >
              <img
                src={logo}
                alt="logo"
                className="header__logo__img header__logo__img--desktop"
              />
              <img
                src={logoMobile}
                alt="logo"
                className="header__logo__img header__logo__img--mobile"
              />
            </TransitionLink>
          )}
          {isMobile && (
            <Link
              className={`link header__logo header__logo--${props.tpl}`}
              to={`${props.lang}`}
            >
              <img
                src={logo}
                alt="logo"
                className="header__logo__img header__logo__img--desktop"
              />
              <img
                src={logoMobile}
                alt="logo"
                className="header__logo__img header__logo__img--mobile"
              />
            </Link>
          )}
        </div>
      </div>
    </header>
  )
}

export default header
