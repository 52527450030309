import React from "react"
import { StaticQuery, graphql } from "gatsby"
import TransitionLink from "gatsby-plugin-transition-link/AniLink"
import { pageTransitionEnter, pageTransitionExit } from "../animations"
import Cookies from "universal-cookie"
import { Transition } from "react-transition-group"
import gsap from "gsap"
import { isMobile } from "react-device-detect"
import { Link } from "gatsby"

const cookies = new Cookies()

class CookieNotice extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      display: false,
      content: this.props.data.allWordpressPage.edges,
      lang: this.props.lang,
    }
  }

  setCookie() {
    cookies.set("privacyPolicy", "seen", { path: "/" })

    this.setState({
      display: false,
    })
  }

  checkCookie() {
    const displayNotice = cookies.get("privacyPolicy") === "seen" ? false : true

    this.setState({
      display: displayNotice,
    })
  }

  componentDidMount() {
    this.checkCookie()
  }

  render() {
    const currentLang = this.state.lang
    const content = this.state.content.find(
      el =>
        el.node.template === "TemplateHome.php" &&
        el.node.polylang_current_lang.split("_")[0] === currentLang
    ).node.acf.cookie_notice

    const ppUrl = `/${currentLang}/${
      content.privacy_policy_button.url.split("/")[1]
    }/`

    return (
      <Transition
        timeout={1000}
        mountOnEnter={true}
        unmountOnExit={true}
        in={this.state.display}
        onEnter={(node, done) => {
          gsap.fromTo(
            node,
            {
              opacity: 0,
            },
            {
              opacity: 1,
              duration: 0.3,
              delay: 0.3,
            }
          )
        }}
        onExit={(node, done) => {
          gsap.fromTo(
            node,
            {
              opacity: 1,
            },
            {
              opacity: 0,
              duration: 0.3,
            }
          )
        }}
      >
        <div id="cookie-notice">
          <div className="grid">
            {content.text && (
              <p dangerouslySetInnerHTML={{ __html: content.text }} />
            )}
            <div className="cookie-notice__controls">
              {!isMobile && (
                <Link
                  to={ppUrl}
                  className="btn btn--plain btn--plain--blue"
                  dangerouslySetInnerHTML={{
                    __html: content.privacy_policy_button.title,
                  }}
                />
              )}
              {isMobile && (
                <TransitionLink
                  to={ppUrl}
                  className="btn btn--plain btn--plain--blue"
                  dangerouslySetInnerHTML={{
                    __html: content.privacy_policy_button.title,
                  }}
                  exit={pageTransitionExit()}
                  entry={pageTransitionEnter()}
                />
              )}
              <button
                className="btn btn--plain btn--plain--blue js-cookie-accept"
                dangerouslySetInnerHTML={{
                  __html: content.accept_button_title,
                }}
                onClick={() => this.setCookie()}
              />
            </div>
          </div>
        </div>
      </Transition>
    )
  }
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        allWordpressPage {
          edges {
            node {
              wordpress_id
              title
              template
              polylang_current_lang
              acf {
                cookie_notice {
                  text
                  accept_button_title
                  privacy_policy_button {
                    title
                    url
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={data => <CookieNotice data={data} {...props} />}
  />
)
