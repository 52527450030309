import React from "react"
import * as Animations from "../animations"
import Div100vh from "react-div-100vh"

class Points extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      location: this.props.location,
      modifier: this.props.modifier,
      theme: this.props.theme,
      showLogo: this.props.logo,
    }
  }

  render() {
    const locationClass = `points--${this.state.location}`

    let modifierClass
    if (this.state.modifier) {
      modifierClass = `points--${this.state.modifier}`
    } else {
      modifierClass = false
    }

    let themeCLass
    if (this.state.theme === "dark") {
      themeCLass = `points--dark`
    } else {
      themeCLass = false
    }

    const classList = `${locationClass} ${themeCLass ? themeCLass : ""}  ${
      modifierClass ? modifierClass : ""
    }`

    return (
      <Div100vh className={"points " + classList}>
        <div className="grid grid--points">
          <div className="points__row points__row--upper">
            <i className="point"></i>
            <i className="point"></i>
            <i className="point"></i>
            <i className="point"></i>
            <i className="point"></i>
          </div>
          <div className="points__row points__row--middle">
            <i className="point"></i>
            <i className="point"></i>
            <i className="point"></i>
            <i className="point"></i>
            <i className="point"></i>
          </div>
          <div className="points__row points__row--lower">
            <i className="point"></i>
            <i className="point"></i>
            <i className="point js-scroll-down" role="button" tabIndex={0}>
              <span
                className="bubble-parent"
                role="button"
                tabIndex={0}
                onMouseEnter={e => {
                  Animations.bubble(
                    e.target.children[0],
                    e.target.closest(".bubble-parent")
                  )
                }}
              >
                <span className="bubble" />
              </span>
            </i>
            <i className="point"></i>
            <i className="point"></i>
          </div>
        </div>
      </Div100vh>
    )
  }
}

export default Points
