import React from "react"
import { pageTransitionEnter, pageTransitionExit } from "../animations"
import TransitionLink from "gatsby-plugin-transition-link/AniLink"
import { isMobile } from "react-device-detect"
import { Link } from "gatsby"

export const primary = (
  color,
  link,
  title,
  external = false,
  target = "_self"
) => {
  return (
    <div>
      {external === false && !isMobile && (
        <TransitionLink
          to={link}
          className={"btn btn--oval btn--oval--" + color}
          exit={pageTransitionExit()}
          entry={pageTransitionEnter()}
        >
          {title}
        </TransitionLink>
      )}
      {external === false && isMobile && (
        <Link to={link} className={"btn btn--oval btn--oval--" + color}>
          {title}
        </Link>
      )}
      {external === true && (
        <a
          href={link}
          target="_blank"
          rel="noopener noreferrer"
          className={"btn btn--oval btn--oval--" + color}
        >
          {title}
        </a>
      )}
    </div>
  )
}
